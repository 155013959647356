/**
 * Validator Mixin
 *
 * This mixin provides custom validation functions.
 * This is intented to be used in form validation.
 */

export default {
  data() {
    return {
      reg: /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,20}$/,
      registerForm: null,
      deeplinkForm: null,
      messagePassword: '',
      confirmationPassword: '',
      messageEmail: '',
      confirmationEmail: '',
    };
  },
  methods: {
    validatePassword(rule, value, callback) {
      if (!this.reg.test(value)) {
        return callback(new Error(this.messagePassword));
      }
      return callback();
    },

    validateConfirmPassword(rule, value, callback) {
      if (!this.reg.test(value)) {
        return callback(new Error(this.messagePassword));
      }
      if (this.registerForm && this.registerForm.confirmPassword !== this.registerForm.password) {
        return callback(new Error(this.confirmationPassword));
      }
      if (this.deeplinkForm && this.deeplinkForm.confirmPassword !== this.deeplinkForm.password) {
        return callback(new Error(this.confirmationPassword));
      }
      return callback();
    },

    validateConfirmEmail(rule, value, callback) {
      if (this.registerForm && this.registerForm.confirmEmail !== this.registerForm.email) {
        return callback(new Error(this.confirmationEmail));
      }
      if (this.deeplinkForm && this.deeplinkForm.confirmEmail !== this.deeplinkForm.email) {
        return callback(new Error(this.confirmationEmail));
      }
      return callback();
    },
  },
};
